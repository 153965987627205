html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em";
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  margin: 0;
  padding: 0;
  font-family: IBM Plex Sans, Helvetica, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
}

img {
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
  max-width: 100%;
  height: auto;
  display: inline-block;
}

textarea {
  border-radius: 0;
  height: auto;
  min-height: 50px;
}

select {
  box-sizing: border-box;
  border-radius: 0;
  width: 100%;
}

.map_canvas img, .map_canvas embed, .map_canvas object, .mqa-display img, .mqa-display embed, .mqa-display object {
  max-width: none !important;
}

button {
  appearance: none;
  cursor: auto;
  background: none;
  border: 0;
  border-radius: 0;
  padding: 0;
  line-height: 1;
}

[data-whatinput="mouse"] button {
  outline: 0;
}

pre {
  overflow: auto;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
}

.is-visible {
  display: block !important;
}

.is-hidden {
  display: none !important;
}

.row {
  max-width: 81.25em;
  margin-left: auto;
  margin-right: auto;
}

.row:before, .row:after {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

.row.collapse > .column, .row.collapse > .columns {
  padding-left: 0;
  padding-right: 0;
}

.row .row {
  margin-left: -.625rem;
  margin-right: -.625rem;
}

@media print, screen and (width >= 40em) {
  .row .row {
    margin-left: -.9375rem;
    margin-right: -.9375rem;
  }
}

@media print, screen and (width >= 64em) {
  .row .row {
    margin-left: -.9375rem;
    margin-right: -.9375rem;
  }
}

.row .row.collapse {
  margin-left: 0;
  margin-right: 0;
}

.row.expanded {
  max-width: none;
}

.row.expanded .row {
  margin-left: auto;
  margin-right: auto;
}

.row:not(.expanded) .row {
  max-width: none;
}

.row.gutter-small > .column, .row.gutter-small > .columns {
  padding-left: .625rem;
  padding-right: .625rem;
}

.row.gutter-medium > .column, .row.gutter-medium > .columns {
  padding-left: .9375rem;
  padding-right: .9375rem;
}

.column, .columns {
  float: left;
  width: 100%;
  padding-left: .625rem;
  padding-right: .625rem;
}

@media print, screen and (width >= 40em) {
  .column, .columns {
    padding-left: .9375rem;
    padding-right: .9375rem;
  }
}

.column, .columns, .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
  float: left;
  clear: none;
}

.column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
  float: right;
}

.column.end:last-child:last-child, .end.columns:last-child:last-child {
  float: left;
}

.column.row.row, .row.row.columns {
  float: none;
}

.row .column.row.row, .row .row.row.columns {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.small-1 {
  width: 8.33333%;
}

.small-push-1 {
  position: relative;
  left: 8.33333%;
}

.small-pull-1 {
  position: relative;
  left: -8.33333%;
}

.small-offset-0 {
  margin-left: 0%;
}

.small-2 {
  width: 16.6667%;
}

.small-push-2 {
  position: relative;
  left: 16.6667%;
}

.small-pull-2 {
  position: relative;
  left: -16.6667%;
}

.small-offset-1 {
  margin-left: 8.33333%;
}

.small-3 {
  width: 25%;
}

.small-push-3 {
  position: relative;
  left: 25%;
}

.small-pull-3 {
  position: relative;
  left: -25%;
}

.small-offset-2 {
  margin-left: 16.6667%;
}

.small-4 {
  width: 33.3333%;
}

.small-push-4 {
  position: relative;
  left: 33.3333%;
}

.small-pull-4 {
  position: relative;
  left: -33.3333%;
}

.small-offset-3 {
  margin-left: 25%;
}

.small-5 {
  width: 41.6667%;
}

.small-push-5 {
  position: relative;
  left: 41.6667%;
}

.small-pull-5 {
  position: relative;
  left: -41.6667%;
}

.small-offset-4 {
  margin-left: 33.3333%;
}

.small-6 {
  width: 50%;
}

.small-push-6 {
  position: relative;
  left: 50%;
}

.small-pull-6 {
  position: relative;
  left: -50%;
}

.small-offset-5 {
  margin-left: 41.6667%;
}

.small-7 {
  width: 58.3333%;
}

.small-push-7 {
  position: relative;
  left: 58.3333%;
}

.small-pull-7 {
  position: relative;
  left: -58.3333%;
}

.small-offset-6 {
  margin-left: 50%;
}

.small-8 {
  width: 66.6667%;
}

.small-push-8 {
  position: relative;
  left: 66.6667%;
}

.small-pull-8 {
  position: relative;
  left: -66.6667%;
}

.small-offset-7 {
  margin-left: 58.3333%;
}

.small-9 {
  width: 75%;
}

.small-push-9 {
  position: relative;
  left: 75%;
}

.small-pull-9 {
  position: relative;
  left: -75%;
}

.small-offset-8 {
  margin-left: 66.6667%;
}

.small-10 {
  width: 83.3333%;
}

.small-push-10 {
  position: relative;
  left: 83.3333%;
}

.small-pull-10 {
  position: relative;
  left: -83.3333%;
}

.small-offset-9 {
  margin-left: 75%;
}

.small-11 {
  width: 91.6667%;
}

.small-push-11 {
  position: relative;
  left: 91.6667%;
}

.small-pull-11 {
  position: relative;
  left: -91.6667%;
}

.small-offset-10 {
  margin-left: 83.3333%;
}

.small-12 {
  width: 100%;
}

.small-offset-11 {
  margin-left: 91.6667%;
}

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%;
}

.small-up-1 > .column:nth-of-type(n), .small-up-1 > .columns:nth-of-type(n) {
  clear: none;
}

.small-up-1 > .column:nth-of-type(n+1), .small-up-1 > .columns:nth-of-type(n+1) {
  clear: both;
}

.small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
  float: left;
}

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%;
}

.small-up-2 > .column:nth-of-type(n), .small-up-2 > .columns:nth-of-type(n) {
  clear: none;
}

.small-up-2 > .column:nth-of-type(odd), .small-up-2 > .columns:nth-of-type(odd) {
  clear: both;
}

.small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
  float: left;
}

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.3333%;
}

.small-up-3 > .column:nth-of-type(n), .small-up-3 > .columns:nth-of-type(n) {
  clear: none;
}

.small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
  clear: both;
}

.small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
  float: left;
}

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%;
}

.small-up-4 > .column:nth-of-type(n), .small-up-4 > .columns:nth-of-type(n) {
  clear: none;
}

.small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
  clear: both;
}

.small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
  float: left;
}

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%;
}

.small-up-5 > .column:nth-of-type(n), .small-up-5 > .columns:nth-of-type(n) {
  clear: none;
}

.small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
  clear: both;
}

.small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
  float: left;
}

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.6667%;
}

.small-up-6 > .column:nth-of-type(n), .small-up-6 > .columns:nth-of-type(n) {
  clear: none;
}

.small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
  clear: both;
}

.small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
  float: left;
}

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.2857%;
}

.small-up-7 > .column:nth-of-type(n), .small-up-7 > .columns:nth-of-type(n) {
  clear: none;
}

.small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
  clear: both;
}

.small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
  float: left;
}

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%;
}

.small-up-8 > .column:nth-of-type(n), .small-up-8 > .columns:nth-of-type(n) {
  clear: none;
}

.small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
  clear: both;
}

.small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
  float: left;
}

.small-collapse > .column, .small-collapse > .columns {
  padding-left: 0;
  padding-right: 0;
}

.small-collapse .row, .expanded.row .small-collapse.row {
  margin-left: 0;
  margin-right: 0;
}

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-left: .625rem;
  padding-right: .625rem;
}

.small-centered {
  margin-left: auto;
  margin-right: auto;
}

.small-centered, .small-centered:last-child:not(:first-child) {
  float: none;
  clear: both;
}

.small-uncentered, .small-push-0, .small-pull-0 {
  margin-left: 0;
  margin-right: 0;
  position: static;
}

.small-uncentered, .small-uncentered:last-child:not(:first-child), .small-push-0, .small-push-0:last-child:not(:first-child), .small-pull-0, .small-pull-0:last-child:not(:first-child) {
  float: left;
  clear: none;
}

.small-uncentered:last-child:not(:first-child), .small-push-0:last-child:not(:first-child), .small-pull-0:last-child:not(:first-child) {
  float: right;
}

@media print, screen and (width >= 40em) {
  .medium-1 {
    width: 8.33333%;
  }

  .medium-push-1 {
    position: relative;
    left: 8.33333%;
  }

  .medium-pull-1 {
    position: relative;
    left: -8.33333%;
  }

  .medium-offset-0 {
    margin-left: 0%;
  }

  .medium-2 {
    width: 16.6667%;
  }

  .medium-push-2 {
    position: relative;
    left: 16.6667%;
  }

  .medium-pull-2 {
    position: relative;
    left: -16.6667%;
  }

  .medium-offset-1 {
    margin-left: 8.33333%;
  }

  .medium-3 {
    width: 25%;
  }

  .medium-push-3 {
    position: relative;
    left: 25%;
  }

  .medium-pull-3 {
    position: relative;
    left: -25%;
  }

  .medium-offset-2 {
    margin-left: 16.6667%;
  }

  .medium-4 {
    width: 33.3333%;
  }

  .medium-push-4 {
    position: relative;
    left: 33.3333%;
  }

  .medium-pull-4 {
    position: relative;
    left: -33.3333%;
  }

  .medium-offset-3 {
    margin-left: 25%;
  }

  .medium-5 {
    width: 41.6667%;
  }

  .medium-push-5 {
    position: relative;
    left: 41.6667%;
  }

  .medium-pull-5 {
    position: relative;
    left: -41.6667%;
  }

  .medium-offset-4 {
    margin-left: 33.3333%;
  }

  .medium-6 {
    width: 50%;
  }

  .medium-push-6 {
    position: relative;
    left: 50%;
  }

  .medium-pull-6 {
    position: relative;
    left: -50%;
  }

  .medium-offset-5 {
    margin-left: 41.6667%;
  }

  .medium-7 {
    width: 58.3333%;
  }

  .medium-push-7 {
    position: relative;
    left: 58.3333%;
  }

  .medium-pull-7 {
    position: relative;
    left: -58.3333%;
  }

  .medium-offset-6 {
    margin-left: 50%;
  }

  .medium-8 {
    width: 66.6667%;
  }

  .medium-push-8 {
    position: relative;
    left: 66.6667%;
  }

  .medium-pull-8 {
    position: relative;
    left: -66.6667%;
  }

  .medium-offset-7 {
    margin-left: 58.3333%;
  }

  .medium-9 {
    width: 75%;
  }

  .medium-push-9 {
    position: relative;
    left: 75%;
  }

  .medium-pull-9 {
    position: relative;
    left: -75%;
  }

  .medium-offset-8 {
    margin-left: 66.6667%;
  }

  .medium-10 {
    width: 83.3333%;
  }

  .medium-push-10 {
    position: relative;
    left: 83.3333%;
  }

  .medium-pull-10 {
    position: relative;
    left: -83.3333%;
  }

  .medium-offset-9 {
    margin-left: 75%;
  }

  .medium-11 {
    width: 91.6667%;
  }

  .medium-push-11 {
    position: relative;
    left: 91.6667%;
  }

  .medium-pull-11 {
    position: relative;
    left: -91.6667%;
  }

  .medium-offset-10 {
    margin-left: 83.3333%;
  }

  .medium-12 {
    width: 100%;
  }

  .medium-offset-11 {
    margin-left: 91.6667%;
  }

  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%;
  }

  .medium-up-1 > .column:nth-of-type(n), .medium-up-1 > .columns:nth-of-type(n) {
    clear: none;
  }

  .medium-up-1 > .column:nth-of-type(n+1), .medium-up-1 > .columns:nth-of-type(n+1) {
    clear: both;
  }

  .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
    float: left;
  }

  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%;
  }

  .medium-up-2 > .column:nth-of-type(n), .medium-up-2 > .columns:nth-of-type(n) {
    clear: none;
  }

  .medium-up-2 > .column:nth-of-type(odd), .medium-up-2 > .columns:nth-of-type(odd) {
    clear: both;
  }

  .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
    float: left;
  }

  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.3333%;
  }

  .medium-up-3 > .column:nth-of-type(n), .medium-up-3 > .columns:nth-of-type(n) {
    clear: none;
  }

  .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }

  .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
    float: left;
  }

  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%;
  }

  .medium-up-4 > .column:nth-of-type(n), .medium-up-4 > .columns:nth-of-type(n) {
    clear: none;
  }

  .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }

  .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
    float: left;
  }

  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%;
  }

  .medium-up-5 > .column:nth-of-type(n), .medium-up-5 > .columns:nth-of-type(n) {
    clear: none;
  }

  .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }

  .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
    float: left;
  }

  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.6667%;
  }

  .medium-up-6 > .column:nth-of-type(n), .medium-up-6 > .columns:nth-of-type(n) {
    clear: none;
  }

  .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }

  .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
    float: left;
  }

  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.2857%;
  }

  .medium-up-7 > .column:nth-of-type(n), .medium-up-7 > .columns:nth-of-type(n) {
    clear: none;
  }

  .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }

  .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
    float: left;
  }

  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%;
  }

  .medium-up-8 > .column:nth-of-type(n), .medium-up-8 > .columns:nth-of-type(n) {
    clear: none;
  }

  .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }

  .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
    float: left;
  }

  .medium-collapse > .column, .medium-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }

  .medium-collapse .row, .expanded.row .medium-collapse.row {
    margin-left: 0;
    margin-right: 0;
  }

  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-left: .9375rem;
    padding-right: .9375rem;
  }

  .medium-centered {
    margin-left: auto;
    margin-right: auto;
  }

  .medium-centered, .medium-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }

  .medium-uncentered, .medium-push-0, .medium-pull-0 {
    margin-left: 0;
    margin-right: 0;
    position: static;
  }

  .medium-uncentered, .medium-uncentered:last-child:not(:first-child), .medium-push-0, .medium-push-0:last-child:not(:first-child), .medium-pull-0, .medium-pull-0:last-child:not(:first-child) {
    float: left;
    clear: none;
  }

  .medium-uncentered:last-child:not(:first-child), .medium-push-0:last-child:not(:first-child), .medium-pull-0:last-child:not(:first-child) {
    float: right;
  }
}

@media print, screen and (width >= 64em) {
  .large-1 {
    width: 8.33333%;
  }

  .large-push-1 {
    position: relative;
    left: 8.33333%;
  }

  .large-pull-1 {
    position: relative;
    left: -8.33333%;
  }

  .large-offset-0 {
    margin-left: 0%;
  }

  .large-2 {
    width: 16.6667%;
  }

  .large-push-2 {
    position: relative;
    left: 16.6667%;
  }

  .large-pull-2 {
    position: relative;
    left: -16.6667%;
  }

  .large-offset-1 {
    margin-left: 8.33333%;
  }

  .large-3 {
    width: 25%;
  }

  .large-push-3 {
    position: relative;
    left: 25%;
  }

  .large-pull-3 {
    position: relative;
    left: -25%;
  }

  .large-offset-2 {
    margin-left: 16.6667%;
  }

  .large-4 {
    width: 33.3333%;
  }

  .large-push-4 {
    position: relative;
    left: 33.3333%;
  }

  .large-pull-4 {
    position: relative;
    left: -33.3333%;
  }

  .large-offset-3 {
    margin-left: 25%;
  }

  .large-5 {
    width: 41.6667%;
  }

  .large-push-5 {
    position: relative;
    left: 41.6667%;
  }

  .large-pull-5 {
    position: relative;
    left: -41.6667%;
  }

  .large-offset-4 {
    margin-left: 33.3333%;
  }

  .large-6 {
    width: 50%;
  }

  .large-push-6 {
    position: relative;
    left: 50%;
  }

  .large-pull-6 {
    position: relative;
    left: -50%;
  }

  .large-offset-5 {
    margin-left: 41.6667%;
  }

  .large-7 {
    width: 58.3333%;
  }

  .large-push-7 {
    position: relative;
    left: 58.3333%;
  }

  .large-pull-7 {
    position: relative;
    left: -58.3333%;
  }

  .large-offset-6 {
    margin-left: 50%;
  }

  .large-8 {
    width: 66.6667%;
  }

  .large-push-8 {
    position: relative;
    left: 66.6667%;
  }

  .large-pull-8 {
    position: relative;
    left: -66.6667%;
  }

  .large-offset-7 {
    margin-left: 58.3333%;
  }

  .large-9 {
    width: 75%;
  }

  .large-push-9 {
    position: relative;
    left: 75%;
  }

  .large-pull-9 {
    position: relative;
    left: -75%;
  }

  .large-offset-8 {
    margin-left: 66.6667%;
  }

  .large-10 {
    width: 83.3333%;
  }

  .large-push-10 {
    position: relative;
    left: 83.3333%;
  }

  .large-pull-10 {
    position: relative;
    left: -83.3333%;
  }

  .large-offset-9 {
    margin-left: 75%;
  }

  .large-11 {
    width: 91.6667%;
  }

  .large-push-11 {
    position: relative;
    left: 91.6667%;
  }

  .large-pull-11 {
    position: relative;
    left: -91.6667%;
  }

  .large-offset-10 {
    margin-left: 83.3333%;
  }

  .large-12 {
    width: 100%;
  }

  .large-offset-11 {
    margin-left: 91.6667%;
  }

  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%;
  }

  .large-up-1 > .column:nth-of-type(n), .large-up-1 > .columns:nth-of-type(n) {
    clear: none;
  }

  .large-up-1 > .column:nth-of-type(n+1), .large-up-1 > .columns:nth-of-type(n+1) {
    clear: both;
  }

  .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
    float: left;
  }

  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%;
  }

  .large-up-2 > .column:nth-of-type(n), .large-up-2 > .columns:nth-of-type(n) {
    clear: none;
  }

  .large-up-2 > .column:nth-of-type(odd), .large-up-2 > .columns:nth-of-type(odd) {
    clear: both;
  }

  .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
    float: left;
  }

  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.3333%;
  }

  .large-up-3 > .column:nth-of-type(n), .large-up-3 > .columns:nth-of-type(n) {
    clear: none;
  }

  .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }

  .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
    float: left;
  }

  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%;
  }

  .large-up-4 > .column:nth-of-type(n), .large-up-4 > .columns:nth-of-type(n) {
    clear: none;
  }

  .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }

  .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
    float: left;
  }

  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%;
  }

  .large-up-5 > .column:nth-of-type(n), .large-up-5 > .columns:nth-of-type(n) {
    clear: none;
  }

  .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }

  .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
    float: left;
  }

  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.6667%;
  }

  .large-up-6 > .column:nth-of-type(n), .large-up-6 > .columns:nth-of-type(n) {
    clear: none;
  }

  .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }

  .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
    float: left;
  }

  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.2857%;
  }

  .large-up-7 > .column:nth-of-type(n), .large-up-7 > .columns:nth-of-type(n) {
    clear: none;
  }

  .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }

  .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
    float: left;
  }

  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%;
  }

  .large-up-8 > .column:nth-of-type(n), .large-up-8 > .columns:nth-of-type(n) {
    clear: none;
  }

  .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }

  .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
    float: left;
  }

  .large-collapse > .column, .large-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }

  .large-collapse .row, .expanded.row .large-collapse.row {
    margin-left: 0;
    margin-right: 0;
  }

  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-left: .9375rem;
    padding-right: .9375rem;
  }

  .large-centered {
    margin-left: auto;
    margin-right: auto;
  }

  .large-centered, .large-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }

  .large-uncentered, .large-push-0, .large-pull-0 {
    margin-left: 0;
    margin-right: 0;
    position: static;
  }

  .large-uncentered, .large-uncentered:last-child:not(:first-child), .large-push-0, .large-push-0:last-child:not(:first-child), .large-pull-0, .large-pull-0:last-child:not(:first-child) {
    float: left;
    clear: none;
  }

  .large-uncentered:last-child:not(:first-child), .large-push-0:last-child:not(:first-child), .large-pull-0:last-child:not(:first-child) {
    float: right;
  }
}

.column-block {
  margin-bottom: 1.25rem;
}

.column-block > :last-child {
  margin-bottom: 0;
}

@media print, screen and (width >= 40em) {
  .column-block {
    margin-bottom: 1.875rem;
  }

  .column-block > :last-child {
    margin-bottom: 0;
  }
}

div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

p {
  font-size: inherit;
  text-rendering: optimizeLegibility;
  margin-bottom: 1rem;
  line-height: 1.5;
}

em, i {
  font-style: italic;
  line-height: inherit;
}

strong, b {
  font-weight: 600;
  line-height: inherit;
}

small {
  font-size: 80%;
  line-height: inherit;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: inherit;
  text-rendering: optimizeLegibility;
  font-family: IBM Plex Sans, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
}

h1 small, .h1 small, h2 small, .h2 small, h3 small, .h3 small, h4 small, .h4 small, h5 small, .h5 small, h6 small, .h6 small {
  color: #777;
  line-height: 0;
}

h1, .h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: 1.4;
}

h2, .h2 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: 1.25rem;
  line-height: 1.4;
}

h3, .h3 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: 1.1875rem;
  line-height: 1.4;
}

h4, .h4 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: 1.125rem;
  line-height: 1.4;
}

h5, .h5 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: 1.0625rem;
  line-height: 1.4;
}

h6, .h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: 1rem;
  line-height: 1.4;
}

@media print, screen and (width >= 40em) {
  h1, .h1 {
    font-size: 3rem;
  }

  h2, .h2 {
    font-size: 2.5rem;
  }

  h3, .h3 {
    font-size: 1.9375rem;
  }

  h4, .h4 {
    font-size: 1.5625rem;
  }

  h5, .h5 {
    font-size: 1.25rem;
  }

  h6, .h6 {
    font-size: 1rem;
  }
}

a {
  line-height: inherit;
  color: #007c82;
  cursor: pointer;
  text-decoration: none;
}

a:hover, a:focus {
  color: #006b70;
}

a img {
  border: 0;
}

hr {
  clear: both;
  border: 0;
  border-bottom: 1px solid #777;
  max-width: 81.25em;
  height: 0;
  margin: 1.25rem auto;
}

ul, ol, dl {
  margin-bottom: 1rem;
  line-height: 1.5;
  list-style-position: outside;
}

li {
  font-size: inherit;
}

ul {
  margin-left: 1.25rem;
  list-style-type: disc;
}

ol {
  margin-left: 1.25rem;
}

ul ul, ol ul, ul ol, ol ol {
  margin-bottom: 0;
  margin-left: 1.25rem;
}

dl {
  margin-bottom: 1rem;
}

dl dt {
  margin-bottom: .3rem;
  font-weight: 600;
}

blockquote {
  border-left: 1px solid #777;
  margin: 0 0 1rem;
  padding: .5625rem 1.25rem 0 1.1875rem;
}

blockquote, blockquote p {
  color: #8a8a8a;
  line-height: 1.5;
}

abbr, abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #0a0a0a;
  text-decoration: none;
}

figure {
  margin: 0;
}

kbd {
  color: #0a0a0a;
  background-color: #e6e6e6;
  margin: 0;
  padding: .125rem .25rem 0;
  font-family: IBM Plex Mono, Monaco, Consolas, Lucida Console, monospace;
}

.subheader {
  color: #8a8a8a;
  margin-top: .2rem;
  margin-bottom: .5rem;
  font-weight: normal;
  line-height: 1.4;
}

.lead {
  font-size: 135%;
  line-height: 1.6;
}

.stat {
  font-size: 2.5rem;
  line-height: 1;
}

p + .stat {
  margin-top: -1rem;
}

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none;
}

.cite-block, cite {
  color: #8a8a8a;
  font-size: .8125rem;
  display: block;
}

.cite-block:before, cite:before {
  content: "— ";
}

.code-inline, code {
  color: #0a0a0a;
  word-wrap: break-word;
  background-color: #e6e6e6;
  border: 1px solid #777;
  max-width: 100%;
  padding: .125rem .3125rem .0625rem;
  font-family: IBM Plex Mono, Monaco, Consolas, Lucida Console, monospace;
  font-weight: normal;
  display: inline;
}

.code-block {
  color: #0a0a0a;
  white-space: pre;
  background-color: #e6e6e6;
  border: 1px solid #777;
  margin-bottom: 1.5rem;
  padding: 1rem;
  font-family: IBM Plex Mono, Monaco, Consolas, Lucida Console, monospace;
  font-weight: normal;
  display: block;
  overflow: auto;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media print, screen and (width >= 40em) {
  .medium-text-left {
    text-align: left;
  }

  .medium-text-right {
    text-align: right;
  }

  .medium-text-center {
    text-align: center;
  }

  .medium-text-justify {
    text-align: justify;
  }
}

@media print, screen and (width >= 64em) {
  .large-text-left {
    text-align: left;
  }

  .large-text-right {
    text-align: right;
  }

  .large-text-center {
    text-align: center;
  }

  .large-text-justify {
    text-align: justify;
  }
}

.show-for-print {
  display: none !important;
}

@media print {
  * {
    color-adjust: economy;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: none !important;
  }

  .show-for-print {
    display: block !important;
  }

  .hide-for-print {
    display: none !important;
  }

  table.show-for-print {
    display: table !important;
  }

  thead.show-for-print {
    display: table-header-group !important;
  }

  tbody.show-for-print {
    display: table-row-group !important;
  }

  tr.show-for-print {
    display: table-row !important;
  }

  td.show-for-print, th.show-for-print {
    display: table-cell !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
    content: "";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #8a8a8a;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: .5cm;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  .print-break-inside {
    page-break-inside: auto;
  }
}

.breadcrumbs {
  margin: 0 0 1rem;
  list-style: none;
}

.breadcrumbs:before, .breadcrumbs:after {
  content: " ";
  display: table;
}

.breadcrumbs:after {
  clear: both;
}

.breadcrumbs li {
  float: left;
  color: #0a0a0a;
  cursor: default;
  text-transform: uppercase;
  font-size: .6875rem;
}

.breadcrumbs li:not(:last-child):after {
  opacity: 1;
  content: "/";
  color: #777;
  margin: 0 .75rem;
  position: relative;
}

.breadcrumbs a {
  color: #007c82;
}

.breadcrumbs a:hover {
  text-decoration: underline;
}

.breadcrumbs .disabled {
  color: #777;
  cursor: not-allowed;
}

.menu {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

[data-whatinput="mouse"] .menu li {
  outline: 0;
}

.menu a, .menu .button {
  padding: .7rem 1rem;
  line-height: 1;
  text-decoration: none;
  display: block;
}

.menu input, .menu select, .menu a, .menu button {
  margin-bottom: 0;
}

.menu input, .menu li, .menu.horizontal li {
  display: inline-block;
}

.menu.vertical li {
  display: block;
}

.menu.expanded {
  width: 100%;
  display: table;
}

.menu.expanded > li {
  vertical-align: middle;
  display: table-cell;
}

.menu.simple li + li {
  margin-left: 1rem;
}

.menu.simple a {
  padding: 0;
}

@media print, screen and (width >= 40em) {
  .menu.medium-horizontal li {
    display: inline-block;
  }

  .menu.medium-vertical li {
    display: block;
  }

  .menu.medium-expanded {
    width: 100%;
    display: table;
  }

  .menu.medium-expanded > li {
    vertical-align: middle;
    display: table-cell;
  }

  .menu.medium-simple {
    width: 100%;
    display: table;
  }

  .menu.medium-simple > li {
    vertical-align: middle;
    display: table-cell;
  }
}

@media print, screen and (width >= 64em) {
  .menu.large-horizontal li {
    display: inline-block;
  }

  .menu.large-vertical li {
    display: block;
  }

  .menu.large-expanded {
    width: 100%;
    display: table;
  }

  .menu.large-expanded > li {
    vertical-align: middle;
    display: table-cell;
  }

  .menu.large-simple {
    width: 100%;
    display: table;
  }

  .menu.large-simple > li {
    vertical-align: middle;
    display: table-cell;
  }
}

.menu.nested {
  margin-left: 1rem;
  margin-right: 0;
}

.menu.icons img, .menu.icons i, .menu.icons svg, .menu.icons img + span, .menu.icons i + span, .menu.icons svg + span, .menu.icon-top img, .menu.icon-top i, .menu.icon-top svg, .menu.icon-right img, .menu.icon-right i, .menu.icon-right svg, .menu.icon-bottom img, .menu.icon-bottom i, .menu.icon-bottom svg, .menu.icon-left img, .menu.icon-left i, .menu.icon-left svg, .menu.icon-top img + span, .menu.icon-top i + span, .menu.icon-top svg + span, .menu.icon-right img + span, .menu.icon-right i + span, .menu.icon-right svg + span, .menu.icon-bottom img + span, .menu.icon-bottom i + span, .menu.icon-bottom svg + span, .menu.icon-left img + span, .menu.icon-left i + span, .menu.icon-left svg + span {
  vertical-align: middle;
}

.menu.icon-left li a img, .menu.icon-left li a i, .menu.icon-left li a svg {
  margin-right: .25rem;
  display: inline-block;
}

.menu.icon-right li a img, .menu.icon-right li a i, .menu.icon-right li a svg {
  margin-left: .25rem;
  display: inline-block;
}

.menu.icon-top li a {
  text-align: center;
}

.menu.icon-top li a img, .menu.icon-top li a i, .menu.icon-top li a svg {
  margin: 0 auto .25rem;
  display: block;
}

.menu.icon-bottom li a {
  text-align: center;
}

.menu.icon-bottom li a img, .menu.icon-bottom li a i, .menu.icon-bottom li a svg {
  margin: .25rem auto 0;
  display: block;
}

.menu .is-active > a, .menu .active > a {
  color: #fefefe;
  background: #007c82;
}

.menu.align-left {
  text-align: left;
}

.menu.align-right {
  text-align: right;
}

.menu.align-right .submenu li {
  text-align: left;
}

.menu.align-right.vertical .submenu li {
  text-align: right;
}

.menu.align-right .nested {
  margin-left: 0;
  margin-right: 1rem;
}

.menu.align-center {
  text-align: center;
}

.menu.align-center .submenu li {
  text-align: left;
}

.menu .menu-text {
  color: inherit;
  padding: .7rem 1rem;
  font-weight: bold;
  line-height: 1;
}

.menu-centered > .menu {
  text-align: center;
}

.menu-centered > .menu .submenu li {
  text-align: left;
}

.no-js [data-responsive-menu] ul {
  display: none;
}

.dropdown-pane {
  z-index: 10;
  visibility: hidden;
  background-color: #fff;
  border: 1px solid #777;
  border-radius: 0;
  width: 300px;
  padding: 1rem;
  font-size: 1rem;
  display: none;
  position: absolute;
}

.dropdown-pane.is-opening {
  display: block;
}

.dropdown-pane.is-open {
  visibility: visible;
  display: block;
}

.dropdown-pane.tiny {
  width: 100px;
}

.dropdown-pane.small {
  width: 200px;
}

.dropdown-pane.large {
  width: 400px;
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

@media print, screen and (width <= 39.9988em) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media screen and (width <= 0), screen and (width >= 40em) {
  .show-for-small-only {
    display: none !important;
  }
}

@media print, screen and (width >= 40em) {
  .hide-for-medium {
    display: none !important;
  }
}

@media screen and (width <= 39.9988em) {
  .show-for-medium {
    display: none !important;
  }
}

@media print, screen and (width >= 40em) and (width <= 63.9988em) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media screen and (width <= 39.9988em), screen and (width >= 64em) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media print, screen and (width >= 64em) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (width <= 63.9988em) {
  .show-for-large {
    display: none !important;
  }
}

@media print, screen and (width >= 64em) and (width <= 74.9988em) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (width <= 63.9988em), screen and (width >= 75em) {
  .show-for-large-only {
    display: none !important;
  }
}

.show-for-sr, .show-on-focus {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.show-on-focus:active, .show-on-focus:focus {
  clip: auto !important;
  white-space: normal !important;
  width: auto !important;
  height: auto !important;
  position: static !important;
  overflow: visible !important;
}

.show-for-landscape, .hide-for-portrait {
  display: block !important;
}

@media screen and (orientation: landscape) {
  .show-for-landscape, .hide-for-portrait {
    display: block !important;
  }
}

@media screen and (orientation: portrait) {
  .show-for-landscape, .hide-for-portrait {
    display: none !important;
  }
}

.hide-for-landscape, .show-for-portrait {
  display: none !important;
}

@media screen and (orientation: landscape) {
  .hide-for-landscape, .show-for-portrait {
    display: none !important;
  }
}

@media screen and (orientation: portrait) {
  .hide-for-landscape, .show-for-portrait {
    display: block !important;
  }
}

.is-visible-block {
  display: block !important;
}

.is-visible-inline {
  display: inline-block !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

li {
  word-wrap: break-word;
}

h1, h2, h3, h4 {
  font-weight: 600;
}

h5 {
  font-weight: 500;
}

a, a:visited {
  border-bottom-style: dotted;
  border-bottom-width: 1px;
  border-bottom-color: inherit;
}

a.active, a:hover, a:active {
  border-bottom-style: solid;
}

a.special {
  border-bottom-style: double;
  border-bottom-width: 3px;
  padding: .1em .3em;
  font-style: italic;
}

a:focus {
  box-shadow: 0 0 2px #64646480;
}

a:hover {
  opacity: .9;
}

a.image, a.image:visited {
  border-bottom: 0;
}

a.button, a.button:visited {
  color: #f8f8f8;
  white-space: nowrap;
}

legend.label {
  display: block;
}

ul.menu.secondary-menu, .secondary-menu ul.menu {
  padding-bottom: 1rem;
}

ul.menu.secondary-menu a, ul.menu.secondary-menu a:hover, .secondary-menu ul.menu a, .secondary-menu ul.menu a:hover {
  border-bottom: none;
}

ul.menu.secondary-menu li, .secondary-menu ul.menu li {
  display: inline-block;
}

.callout.tiny {
  padding: 0;
}

.breadcrumbs {
  padding-top: .5rem;
}

body .breadcrumbs a {
  color: #777;
  border-bottom: none;
}

#local-title.logo-only img, #local-title.logo-title img, #local-title.logo-title-strapline img {
  float: left;
  margin: 1em .69em .69em;
}

#local-title.logo-title span, #local-title.logo-title-strapline span {
  float: left;
  margin: 1em 0 .69em;
  display: block;
}

#local-title.logo-title span {
  margin-top: 2.3em;
}

#local-title.logo-title h1, #local-title.logo-title-strapline h1 {
  line-height: 100%;
  display: inline;
}

#local-title.logo-title-strapline h1 {
  font-size: 270%;
}

#local-title.logo-title-strapline p {
  font-size: 123.1%;
  display: inline;
}

ul.simple.vertical.menu {
  margin-left: 0;
  font-size: .9rem;
}

ul.simple.vertical.menu li {
  margin-bottom: .5rem;
  display: block;
}

ul.simple.vertical.menu li > a {
  padding: 0;
  display: inline;
}

ul.simple.vertical.menu li > a.active:before {
  content: "9 ";
  font-family: EBI-Functional;
}

ul.simple.vertical.menu li > ul.simple.menu {
  margin: .5rem;
}

.cookie-banner {
  color: #eee;
  background-color: #373a36;
  width: 100%;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
}

.cookie-banner a, .cookie-banner a:hover {
  color: #f8f8f8;
}

.cookie-banner .text {
  margin-right: 2em;
}

body .thumbnail {
  box-shadow: none;
}

.caption {
  opacity: .8;
  font-size: .9rem;
  line-height: 1.4em;
  overflow: hidden;
}

figure.image {
  width: 1px;
  padding-left: .5rem;
  display: table;
}

figure.image img, figure.image figcaption {
  max-width: inherit;
}

figure.image figcaption {
  opacity: .8;
  margin: .75rem 0;
  font-family: sans-serif;
  font-size: .9rem;
  line-height: 1.4em;
  display: table-row;
  overflow: hidden;
}

.columns > div.cke_widget_image:first-child > figure.image, .columns > div.cke_widget_image:first-child > figure.image img, .columns > figure.image:first-child, .columns > figure.image:first-child img {
  width: auto;
  max-width: 100%;
  display: block;
}

a.highlight-caption, .highlight-caption {
  z-index: 1;
  color: #fff;
  background: #333;
  border-bottom: none;
  padding: .25rem .5rem;
  position: relative;
  top: -3rem;
}

.highlight-caption.highlight-caption-big {
  margin-bottom: -5rem;
  display: inline-block;
  top: -8rem;
}

.position-relative > .highlight-caption.highlight-caption-big {
  margin-bottom: 0;
  position: absolute;
  top: auto;
  bottom: 3%;
}

body .has-tip {
  background: #00000008;
  font-weight: normal;
  line-height: 1em;
}

blockquote.float-left {
  text-align: right;
  border-left: none;
  border-right: 1px solid #cacaca;
  margin-right: 1rem;
}

blockquote.lead {
  border-left-color: #0000;
  border-right-color: #0000;
  margin-left: 1rem;
}

blockquote.lead.quote:before {
  content: "“";
  height: 0;
  font-size: 3rem;
  display: block;
  position: relative;
  top: -1.5rem;
  left: -1.5rem;
}

blockquote.drop-quote:first-line {
  font-size: 1.6rem;
}

@media screen and (width <= 75rem) {
  body ul.dropdown.menu.float-left {
    padding-left: .5rem;
  }

  body h1 {
    font-size: 2.5rem;
  }

  body h2 {
    font-size: 2rem;
  }

  body h3 {
    font-size: 1.5rem;
  }

  body h4 {
    font-size: 1.25rem;
  }
}

nav.orbit-bullets button.thumbnail {
  border-radius: 0;
  width: auto;
}

nav.orbit-bullets button.thumbnail.is-active {
  box-shadow: 0 0 1px #444;
}

nav.orbit-bullets button.thumbnail.image {
  width: 4rem;
  height: 3rem;
  overflow: hidden;
}

nav.orbit-bullets button.thumbnail.image > img {
  min-width: 6rem;
  margin-left: -2rem;
}

.columns.no-pad-right {
  padding-right: 0;
}

.columns.no-pad-left {
  padding-left: 0;
}

.button:focus, .button:hover, .button.hover {
  opacity: .8;
}

.tabs-title > a {
  border-bottom: none;
}

.tabs-title > a:hover {
  color: #fff;
  background-color: #007c82;
}

.tabs-title > a:focus, .tabs-title > a[aria-selected="true"] {
  color: #fff;
  background: #555;
}

.button-grid a.button.columns {
  border: 0;
  border-right: 1px solid #fff;
  margin-bottom: 1px;
}

.intro-unit {
  padding-top: 3rem;
  padding-bottom: 2rem;
}

.intro-unit h2 {
  margin-bottom: 0;
  font-size: 3rem;
}

.intro-unit h3 {
  padding-bottom: 1rem;
}

.intro-unit .lead {
  color: #555;
  margin-bottom: .5rem;
}

a.external:after {
  content: " x";
  margin-left: .4rem;
  font-family: EBI-Generic;
  font-size: .65rem;
  position: relative;
  top: -1px;
}

a.read-more, a.readmore, a.more {
  white-space: nowrap;
}

a.read-more:after, a.readmore:after, a.more:after {
  content: " >";
  font-family: EBI-Functional;
  font-size: .85em;
}

@media screen and (width >= 40em) {
  body.full-width .row, body.full-width .row .row {
    max-width: 150rem;
  }
}

dd {
  padding-bottom: 1rem;
}

dl dt {
  font-size: 1.1rem;
  font-weight: 400;
}

dl dt a {
  color: #555;
  border-bottom: none;
}

dl dt a:hover {
  border-bottom: 1px dotted;
}

.title-cap {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.font-embl {
  font-family: IBM Plex Sans, Helvetica, Arial, sans-serif;
}

.font-code, .font-serif, .serif {
  font-family: IBM Plex Mono, Monaco, Consolas, Lucida Console, monospace;
}

.font-sans-serif, .sans-serif {
  font-family: IBM Plex Sans, Helvetica, Arial, sans-serif;
}

.block {
  display: block;
}

.clear {
  clear: both;
}

.small {
  font-size: 80%;
  line-height: inherit;
}

.nowrap {
  white-space: nowrap;
}

.hidden, .hide {
  display: none;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-static {
  position: static;
}

.position-fixed {
  position: fixed;
}

.no-margin {
  margin: 0;
}

.no-underline a, a.no-underline {
  border-bottom: none;
  text-decoration: none;
}

.no-underline a a.underline, .no-underline a .underline, a.no-underline a.underline, a.no-underline .underline {
  border-bottom-style: dotted;
  border-bottom-width: 1px;
  border-bottom-color: inherit;
}

.no-underline a:hover a.underline, .no-underline a:hover .underline, a.no-underline:hover a.underline, a.no-underline:hover .underline {
  border-bottom: 0 #0000;
}

ul.leaders {
  max-width: 40em;
  padding: 0;
  list-style: none;
  overflow-x: hidden;
}

ul.leaders li:before {
  float: left;
  white-space: nowrap;
  content: ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . ";
  width: 0;
}

ul.leaders span:first-child {
  background: #fff;
  padding-right: .33em;
}

ul.leaders span + span {
  float: right;
  background: #fff;
  padding-left: .33em;
}

ul.inline li {
  display: inline-block;
}

li.icon-bullet {
  text-indent: -1.3em;
  list-style-type: none;
}

ul li a.badge, ul li.badge {
  font-size: inherit;
}

.margin-top-none {
  margin-top: 0;
}

.margin-top-xsmall {
  margin-top: .1rem;
}

.margin-top-small {
  margin-top: .25rem;
}

.margin-top-medium {
  margin-top: .5rem;
}

.margin-top-large {
  margin-top: 1rem;
}

.margin-top-xlarge {
  margin-top: 2rem;
}

.margin-left-none {
  margin-left: 0;
}

.margin-left-xsmall {
  margin-left: .1rem;
}

.margin-left-small {
  margin-left: .25rem;
}

.margin-left-medium {
  margin-left: .5rem;
}

.margin-left-large {
  margin-left: 1rem;
}

.margin-left-xlarge {
  margin-left: 2rem;
}

.margin-right-none {
  margin-right: 0;
}

.margin-right-xsmall {
  margin-right: .1rem;
}

.margin-right-small {
  margin-right: .25rem;
}

.margin-right-medium {
  margin-right: .5rem;
}

.margin-right-large {
  margin-right: 1rem;
}

.margin-right-xlarge {
  margin-right: 2rem;
}

.margin-bottom-none {
  margin-bottom: 0;
}

.margin-bottom-xsmall {
  margin-bottom: .1rem;
}

.margin-bottom-small {
  margin-bottom: .25rem;
}

.margin-bottom-medium {
  margin-bottom: .5rem;
}

.margin-bottom-large {
  margin-bottom: 1rem;
}

.margin-bottom-xlarge {
  margin-bottom: 2rem;
}

.padding-top-none {
  padding-top: 0;
}

.padding-top-xsmall {
  padding-top: .1rem;
}

.padding-top-small {
  padding-top: .25rem;
}

.padding-top-medium {
  padding-top: .5rem;
}

.padding-top-large {
  padding-top: 1rem;
}

.padding-top-xlarge {
  padding-top: 2rem;
}

.padding-left-none {
  padding-left: 0;
}

.padding-left-xsmall {
  padding-left: .1rem;
}

.padding-left-small {
  padding-left: .25rem;
}

.padding-left-medium {
  padding-left: .5rem;
}

.padding-left-large {
  padding-left: 1rem;
}

.padding-left-xlarge {
  padding-left: 2rem;
}

.padding-right-none {
  padding-right: 0;
}

.padding-right-xsmall {
  padding-right: .1rem;
}

.padding-right-small {
  padding-right: .25rem;
}

.padding-right-medium {
  padding-right: .5rem;
}

.padding-right-large {
  padding-right: 1rem;
}

.padding-right-xlarge {
  padding-right: 2rem;
}

.padding-bottom-none {
  padding-bottom: 0;
}

.padding-bottom-xsmall {
  padding-bottom: .1rem;
}

.padding-bottom-small {
  padding-bottom: .25rem;
}

.padding-bottom-medium {
  padding-bottom: .5rem;
}

.padding-bottom-large {
  padding-bottom: 1rem;
}

.padding-bottom-xlarge {
  padding-bottom: 2rem;
}

.size-0 {
  font-size: 0%;
}

.size-25 {
  font-size: 25%;
}

.size-50 {
  font-size: 50%;
}

.size-75 {
  font-size: 75%;
}

.size-100 {
  font-size: 100%;
}

.size-125 {
  font-size: 125%;
}

.size-150 {
  font-size: 150%;
}

.size-175 {
  font-size: 175%;
}

.size-200 {
  font-size: 200%;
}

.size-225 {
  font-size: 225%;
}

.size-250 {
  font-size: 250%;
}

.size-275 {
  font-size: 275%;
}

.size-300 {
  font-size: 300%;
}

.size-325 {
  font-size: 325%;
}

.size-350 {
  font-size: 350%;
}

.size-375 {
  font-size: 375%;
}

.size-400 {
  font-size: 400%;
}

.size-425 {
  font-size: 425%;
}

.size-450 {
  font-size: 450%;
}

.size-475 {
  font-size: 475%;
}

.size-500 {
  font-size: 500%;
}

#local-title a {
  border-bottom: none;
}

.masthead #local-title .columns a {
  border-bottom: 1px dotted #f8f8f8;
}

.masthead #local-title .columns a.button, .masthead #local-title .columns a:hover {
  border-bottom: none;
}

nav ul#global-nav.menu li {
  border-right: none;
  display: inline-block;
}

body.no-global-search .masthead-black-bar ul#global-nav.menu li.search {
  display: none;
}

.masthead-black-bar {
  background-color: #373a36;
}

.masthead-black-bar > .row {
  opacity: 0;
}

body.ebi-black-bar-loaded .masthead-black-bar > .row {
  opacity: 1;
  opacity: unset;
}

.masthead-black-bar, .masthead-black-bar a, .masthead-black-bar a:hover {
  color: #fff;
}

.masthead-black-bar a:focus {
  box-shadow: 0 0 2px #f8f8f8;
}

.masthead-black-bar .global-masthead-interactive-banner {
  background-color: #000;
  height: 0;
  display: none;
  overflow: hidden;
}

.masthead-black-bar .global-masthead-interactive-banner.active {
  height: auto;
  display: block;
  overflow: visible;
}

.masthead-black-bar .search-bar .close-button:hover, .masthead-black-bar .search-bar .close-button:focus {
  color: #fff;
}

.masthead-black-bar .global-nav {
  font-size: .9rem;
}

.masthead-black-bar .global-nav a, .masthead-black-bar .global-nav a:hover {
  color: #fff;
  border-bottom: none;
}

.masthead-black-bar .global-nav li a:hover, .masthead-black-bar .global-nav li a:active, .masthead-black-bar .global-nav li a:focus, .masthead-black-bar .global-nav li.active a, .masthead-black-bar .global-nav li.active-trail a {
  color: #fff;
  background-color: #000;
  border-bottom: none;
}

.masthead-black-bar .global-nav li.active a {
  font-weight: 700;
}

.masthead-black-bar .global-nav li a:before {
  padding-right: .4rem;
  font-family: EBI-Generic;
  display: inline-block;
}

.masthead-black-bar .global-nav li.home, .masthead-black-bar .global-nav li.services, .masthead-black-bar .global-nav li.research, .masthead-black-bar .global-nav li.training, .masthead-black-bar .global-nav li.about, .masthead-black-bar .global-nav li.embl, .masthead-black-bar .global-nav li.ebi, .masthead-black-bar .global-nav li.grenoble, .masthead-black-bar .global-nav li.hamburg, .masthead-black-bar .global-nav li.heidelberg, .masthead-black-bar .global-nav li.hinxton, .masthead-black-bar .global-nav li.rome, .masthead-black-bar .global-nav li.barcelona {
  float: right;
}

.masthead-black-bar .global-nav li.home.hover, .masthead-black-bar .global-nav li.services.hover, .masthead-black-bar .global-nav li.research.hover, .masthead-black-bar .global-nav li.training.hover, .masthead-black-bar .global-nav li.about.hover, .masthead-black-bar .global-nav li.embl.hover, .masthead-black-bar .global-nav li.ebi.hover, .masthead-black-bar .global-nav li.grenoble.hover, .masthead-black-bar .global-nav li.hamburg.hover, .masthead-black-bar .global-nav li.heidelberg.hover, .masthead-black-bar .global-nav li.hinxton.hover, .masthead-black-bar .global-nav li.rome.hover, .masthead-black-bar .global-nav li.barcelona.hover {
  float: none;
}

.masthead-black-bar .global-nav li.home.active, .masthead-black-bar .global-nav li.services.active, .masthead-black-bar .global-nav li.research.active, .masthead-black-bar .global-nav li.training.active, .masthead-black-bar .global-nav li.about.active, .masthead-black-bar .global-nav li.embl.active, .masthead-black-bar .global-nav li.ebi.active, .masthead-black-bar .global-nav li.grenoble.active, .masthead-black-bar .global-nav li.hamburg.active, .masthead-black-bar .global-nav li.heidelberg.active, .masthead-black-bar .global-nav li.hinxton.active, .masthead-black-bar .global-nav li.rome.active, .masthead-black-bar .global-nav li.barcelona.active {
  float: left;
}

.masthead-black-bar .global-nav li.embl.hover a:before, .masthead-black-bar .global-nav li.grenoble.hover a:before, .masthead-black-bar .global-nav li.hamburg.hover a:before, .masthead-black-bar .global-nav li.heidelberg.hover a:before, .masthead-black-bar .global-nav li.hinxton.hover a:before, .masthead-black-bar .global-nav li.rome.hover a:before, .masthead-black-bar .global-nav li.barcelona.hover a:before {
  content: "" !important;
}

.masthead-black-bar .global-nav li.where a:before {
  content: "[";
}

.masthead-black-bar .global-nav li.home a:before, .masthead-black-bar .global-nav li.ebi a:before {
  content: "H";
}

.masthead-black-bar .global-nav li.services a:before {
  content: "(";
}

.masthead-black-bar .global-nav li.research a:before {
  content: ")";
}

.masthead-black-bar .global-nav li.training a:before {
  content: "t";
}

.masthead-black-bar .global-nav li.about a:before {
  content: "i";
}

.masthead-black-bar .global-nav li.search a:before {
  content: "1";
  padding-right: 0;
  font-family: EBI-Functional;
}

.masthead-black-bar .global-nav li.search .dropdown-pane {
  color: #fff;
  background: #373a36;
  border: none;
  width: 100%;
  margin-top: -2px;
  left: 0;
}

.masthead-inner {
  color: #fff;
  padding-top: 2.5rem;
}

@media screen and (width <= 39.9375em) {
  .masthead ul.menu.dropdown.float-right > li:last-child {
    margin-bottom: -50px;
  }

  .masthead-black-bar nav ul.menu {
    text-align: right;
  }

  .masthead-black-bar nav ul.menu li {
    float: none;
    display: inline-block;
  }

  .masthead-black-bar nav ul.menu > li > a > img, .masthead-black-bar nav ul.menu > li > a:before {
    margin: 0 auto .25rem;
    font-size: 16px;
    display: block;
  }

  .masthead-black-bar nav ul.menu > li > a {
    text-align: center;
    padding: .6rem .35rem;
    font-size: 10px;
  }
}

#skip-to {
  position: absolute;
  top: -5000px;
}

.masthead {
  background-color: #555;
  background-image: url("data:image/svg+xml;charset=UTF-8,<svg version=\"1.1\" id=\"Layer_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"147 248 60 248\" xml:space=\"preserve\"><polygon class=\"st0\" fill=\"%23000\" fill-opacity=\"0.05\" points=\"147,363.6 259.3,363.6 259.3,428.4 203.2,460.9 147,428.4 \"/></svg>");
  background-position: 100% 82%;
  background-repeat: no-repeat;
  background-size: cover;
}

.masthead a, .masthead button {
  transition: background-color .5s, opacity .5s;
}

.masthead h1, .masthead h4, .masthead p, .masthead a, .masthead a:hover, .masthead a:focus, .masthead a:active, .masthead a:visited {
  color: #f8f8f8;
}

.masthead ul li a {
  border-bottom: 0;
}

.masthead ul li.is-active a, .masthead ul li a:hover, .masthead nav ul li a.hover, .masthead nav ul li a:hover {
  color: #f8f8f8;
  background-color: #373a36e6;
}

.masthead nav ul li.active a, .masthead nav ul li a:active, .masthead ul li.active-trail a, .masthead ul li.active a:visited, .masthead ul li.active-trail a:visited {
  color: #555;
  background-color: #f8f8f8;
  border-bottom: 0;
}

.masthead nav {
  clear: both;
}

.masthead nav ul.menu li {
  float: left;
  border-right: 1px solid #f8f8f8;
  margin-left: 0;
}

.masthead nav ul.menu li:last-child, .masthead nav ul.menu li.active {
  border-right: 0;
}

.masthead nav ul.menu li.float-right {
  border-right: none;
}

.masthead-inner nav i {
  margin-top: -3px;
  font-size: .8em;
  line-height: .9em;
}

div#local-title {
  margin-bottom: 1rem;
}

.masthead.meta-background-image {
  background-position: 100% 100%;
  background-size: initial;
}

.masthead.sticky {
  width: 100%;
  position: static;
  transform: none;
}

.masthead.sticky.is-stuck {
  position: fixed;
  transform: translate3d(0, 0, 0);
}

input#local-searchbox, #local-search [type="submit"] {
  vertical-align: top;
  box-shadow: none;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  margin: 0;
  display: inline-block;
}

#local-search .input-group-button {
  vertical-align: top;
}

body.section-about .masthead {
  background-color: #007c82;
}

body.section-industry .masthead {
  background-color: #317ae2;
}

body.section-research .masthead {
  background-color: #009f4d;
}

body.section-research #local-title h1, body.section-research #local-title p, body.section-research #local-title a {
  color: #fff;
}

body.section-services .masthead {
  background-color: #007b53;
}

body.section-training .masthead {
  background-color: #efc06e;
}

body.section-training #local-title h1, body.section-training #local-title #local-title p, body.section-training #local-title #local-title a {
  color: #373a36;
}

body.section-home .masthead {
  background-color: #007c82;
}

ul.menu.dropdown ul.menu {
  display: none;
}

ul.menu.dropdown ul.menu.is-dropdown-submenu.js-dropdown-active {
  display: block;
}

.dropdown.menu > li.is-dropdown-submenu-parent > a:after {
  border-color: #fff #0000 #0000;
}

.menu > li > a.is-active, .menu > li > a.active {
  font-weight: bold;
}

body .dropdown.menu .has-submenu.is-down-arrow > a:after {
  border-color: #0006 #0000 #0000;
}

body .dropdown.menu .has-submenu.is-right-arrow > a:after {
  border-color: #0000 #0000 #0000 #0006;
}

body header nav .menu.dropdown > .has-submenu.is-down-arrow > a:after {
  border-color: #fff #0000 #0000;
}

.local-footer {
  border-top: 2px solid #eee;
}

.global-footer {
  color: #fefefe;
  background-color: #373a36;
  border-top: 5px solid #009f4d;
  padding-top: 1.5rem;
}

.global-footer h5 {
  margin-top: 1.25rem;
  margin-bottom: 5px;
}

.global-footer .ebi-logo {
  background-image: url("EMBL_EBI_Logo_black.5b81a8d6.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 89%;
  height: 53px;
  margin-left: -.25rem;
  display: block;
  position: relative;
  top: 8px;
}

.global-footer p {
  margin: 0 0 5px;
}

.global-footer ul {
  margin-left: 0;
  font-size: 85%;
  list-style: none;
}

.global-footer a, .global-footer a:visited {
  color: #fefefe;
  border: none;
  text-decoration: none;
}

.global-footer a:hover, .global-footer a:focus, .global-footer a:active {
  color: #fefefe;
  text-decoration: underline;
}

.ebi-footer-meta p {
  margin-bottom: 9px;
  font-size: 85%;
}

h1, h2, h3, h4, h5, h6, a, a:hover, a:focus, a:active {
  color: #0a0a0a;
}

a:visited {
  color: #555;
}

.text-highlight {
  color: #f8f8f8;
  background-color: #555;
  padding: 0 .2em;
}

.callout {
  background: #eaeaea;
  border: none;
}

div.warning {
  border: none;
}

a.ebi-background, .ebi-background, .ebi-background.button, .ebi-background.button:hover, .ebi-background.tag {
  background: #007c82;
}

a.services-background, .services-background, .services-background.button, .services-background.button:hover, .services-background.tag {
  background: #007b53;
}

a.research-background, .research-background, .research-background.button, .research-background.button:hover, .research-background.tag {
  background: #009f4d;
}

a.training-background, .training-background, .training-background.button, .training-background.button:hover, .training-background.tag {
  background: #efc06e;
}

a.industry-background, .industry-background, .industry-background.button, .industry-background.button:hover, .industry-background.tag {
  background: #317ae2;
}

a.elixir-background, .elixir-background, .elixir-background.button, .elixir-background.button:hover, .elixir-background.tag {
  background: #ffa300;
}

a.white-background, .white-background, .white-background.button, .white-background.button:hover, .white-background.tag {
  background: #fff;
}

a.black-background, .black-background, .black-background.button, .black-background.button:hover, .black-background.tag {
  background: #0a0a0a;
}

a.secondary-background, .secondary-background, .secondary-background.button, .secondary-background.button:hover, .secondary-background.tag, .tag.secondary {
  background: #555;
}

a.medium-gray-background, .medium-gray-background, .medium-gray-background.button, .medium-gray-background.button:hover, .medium-gray-background.tag {
  background: #777;
}

.label.ebi-color, .button.ebi-color, a.ebi-color, a:hover.ebi-color, .ebi-color, .ebi-color cite, .ebi-color a, .ebi-color a:hover, .ebi-color a:visited, .ebi-color h3, .ebi-color h4 {
  color: #007c82;
}

.label.services-color, .button.services-color, a.services-color, a:hover.services-color, .services-color, .services-color cite, .services-color a, .services-color a:hover, .services-color a:visited, .services-color h3, .services-color h4 {
  color: #007b53;
}

.label.research-color, .button.research-color, a.research-color, a:hover.research-color, .research-color, .research-color cite, .research-color a, .research-color a:hover, .research-color a:visited, .research-color h3, .research-color h4 {
  color: #009f4d;
}

.label.training-color, .button.training-color, a.training-color, a:hover.training-color, .training-color, .training-color cite, .training-color a, .training-color a:hover, .training-color a:visited, .training-color h3, .training-color h4 {
  color: #efc06e;
}

.label.industry-color, .button.industry-color, a.industry-color, a:hover.industry-color, .industry-color, .industry-color cite, .industry-color a, .industry-color a:hover, .industry-color a:visited, .industry-color h3, .industry-color h4 {
  color: #317ae2;
}

.label.elixir-color, .button.elixir-color, a.elixir-color, a:hover.elixir-color, .elixir-color, .elixir-color cite, .elixir-color a, .elixir-color a:hover, .elixir-color a:visited, .elixir-color h3, .elixir-color h4 {
  color: #ffa300;
}

.label.white-color, .button.white-color, a.white-color, a:hover.white-color, .white-color, .white-color cite, .white-color a, .white-color a:hover, .white-color a:visited, .white-color h3, .white-color h4 {
  color: #fff;
}

.label.black-color, .button.black-color, a.black-color, a:hover.black-color, .black-color, .black-color cite, .black-color a, .black-color a:hover, .black-color a:visited, .black-color h3, .black-color h4 {
  color: #0a0a0a;
}

.label.secondary-color, .button.secondary-color, a.secondary-color, a:hover.secondary-color, .secondary-color, .secondary-color cite, .secondary-color a, .secondary-color a:hover, .secondary-color a:visited, .secondary-color h3, .secondary-color h4 {
  color: #555;
}

.label.medium-gray-color, .button.medium-gray-color, a.medium-gray-color, a:hover.medium-gray-color, .medium-gray-color, .medium-gray-color cite, .medium-gray-color a, .medium-gray-color a:hover, .medium-gray-color a:visited, .medium-gray-color h3, .medium-gray-color h4 {
  color: #777;
}

.masthead-black-bar {
  border-top: 0 solid #eee;
  transition: border-width .25s ease-in-out;
}

.masthead-black-bar.peek {
  border-top: 4px solid #eee;
}

.masthead-black-bar li.embl-selector {
  border-left: .5px solid #ffffff80;
  margin-left: .5rem;
  padding-left: 1.5rem;
}

.masthead-black-bar .embl-bar {
  color: #222;
  background-color: #eee;
}

.masthead-black-bar .embl-bar a {
  color: #222;
}

.masthead-black-bar .embl-bar a:hover {
  border-bottom: 1px solid #fff;
}

.masthead-black-bar .embl-bar .youarehere {
  margin-left: -1.5rem;
}

.masthead-black-bar .dropdown-pane.embl-dropdown {
  color: #fff;
  background-color: #373a36;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  min-width: 30em;
  left: 0;
}

.masthead-black-bar .dropdown-pane.embl-dropdown h6 {
  color: #fff;
  font-weight: 700;
}

.masthead-black-bar .dropdown-pane.embl-dropdown .button {
  color: #007c82;
  background: #fff;
}

.masthead-black-bar .dropdown-pane.embl-dropdown a {
  border-bottom: 1px dashed #555;
}

.masthead-black-bar .dropdown-pane.embl-dropdown a:hover {
  border-bottom: 1px solid #555;
}

@media screen and (width <= 39.9375em) {
  .masthead-black-bar .dropdown-pane.embl-dropdown {
    display: none;
    overflow: hidden;
    width: 0 !important;
  }
}

.masthead-black-bar .embl-selector .button, .masthead-black-bar .embl-selector .button.hover, .masthead-black-bar .embl-selector .button:focus, .masthead-black-bar .embl-selector .button:hover {
  background: url("EMBL-white.3b8cf83e.svg") 100% / 62px no-repeat;
  width: 63px;
  padding-left: 2px;
  padding-right: 17px;
  line-height: 12px;
}

.masthead-black-bar .embl-selector .button.hover, .masthead-black-bar .embl-selector .button:hover {
  cursor: s-resize;
  background-color: #0000;
}

.masthead-black-bar .embl-selector .button[aria-expanded="false"]:after {
  content: "";
  border: 4px inset #0000;
  border-top: 4px solid #fff;
  width: 0;
  height: 0;
  margin-left: 55px;
  display: block;
  position: absolute;
  top: 18px;
}

.masthead-black-bar .embl-selector.embl-ebi .button, .masthead-black-bar .embl-selector.embl-ebi .button.hover, .masthead-black-bar .embl-selector.embl-ebi .button:focus, .masthead-black-bar .embl-selector.embl-ebi .button:hover {
  background: url("EMBL_EBI_Logo_white.5ba02b47.svg") 4px / 100px no-repeat;
  padding-left: 95px;
}

.masthead-black-bar .embl-selector.active {
  background-color: #ddd;
  padding-right: 1rem;
}

.masthead-black-bar .embl-selector.active .button, .masthead-black-bar .embl-selector.active .button.hover, .masthead-black-bar .embl-selector.active .button:focus, .masthead-black-bar .embl-selector.active .button:hover {
  cursor: n-resize;
  background-image: url("EMBL-black.474a19be.svg");
}

.masthead-black-bar .embl-selector.active.embl-ebi .button, .masthead-black-bar .embl-selector.active.embl-ebi .button.hover, .masthead-black-bar .embl-selector.active.embl-ebi .button:focus, .masthead-black-bar .embl-selector.active.embl-ebi .button:hover {
  background-image: url("EMBL_EBI_Logo_black.5b81a8d6.svg");
}

@media screen and (width <= 53em) {
  .masthead-black-bar li.embl-selector {
    padding-left: .5rem;
  }

  .masthead-black-bar .embl-selector .button {
    width: 0;
    padding-right: 1rem;
    overflow: hidden;
  }

  .masthead-black-bar .embl-selector .button:after {
    display: none;
  }

  .masthead-black-bar nav ul.menu li a {
    padding-left: .35rem;
    padding-right: .35rem;
  }
}
/*# sourceMappingURL=site.0aa46f68.css.map */
